import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import InternalCustomerTable from "../../../components/Lists/InternalCustomers/FullTable";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useInternalCustomerForm,
  constants,
  update,
} from "../../../store/internalCustomers";
import { DoubleFieldWrapper } from "../../../components/Forms/Base/Chapters/styles";
import { TextInput } from "../../../components/Forms/Base/Fields";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import Toggle from "../../../components/Toggle/Toggle";
import { cloneDeep } from "lodash";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

const STEPS = {
  SELECT_CUSTOMER: 1,
  EDIT: 2,
  CONFIRM: 3,
};

export default function HandleCustomer() {
  const method = "PATCH";
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [step, setStep] = React.useState(STEPS.SELECT_CUSTOMER);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useInternalCustomerForm("PATCH", selectedCustomer?.id);

  const instance = useFormInstanceField({ storeName, fieldKey: "" });
  const instructions = useSelector((state) => state[storeName].forms[method]);

  const getIsDisabled = (key) =>
    !instructions?.[key] || instructions?.[key]?._readOnly === true;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleSelectedCustomer = ({ original: customer }) => {
    setSelectedCustomer(customer);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: customer,
      })
    );
    setStep(STEPS.EDIT);
  };

  const toggleModule = (module) => {
    const current = instance[module];
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [module]: !current,
        },
      })
    );
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSuccess = () => {
    setLoading(false);
    push("/admin");
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      update({
        id: selectedCustomer.id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
        preProcess: (data) => {
          return preProcess(data, "PATCH");
        },
      })
    );
  };

  const steps = ["1. Välj kund", "2. Redigera", "3. Granska ändringar"];

  const selectStep = () => (
    <DetailPageBox>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj kund</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en kund för att gå till nästa steg
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InternalCustomerTable
        hideColumns
        hideExport
        hideTitle
        onRowClicked={handleSelectedCustomer}
      />
    </DetailPageBox>
  );

  const editStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Gå tillbaka"
        iconType="arrow-back"
        clicked={() => {
          setSelectedCustomer(null);
          setStep(STEPS.SELECT_CUSTOMER);
        }}
      />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Redigera {selectedCustomer?.str_representation}
          </OverviewTitle>
          <OverviewSubtitle></OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <InnerBox>
        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"name"}
            method={method}
            title="Bolagets namn"
          />

          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"orgnr"}
            method={method}
            title="Orgnummer"
          />
        </DoubleFieldWrapper>

        <TextInput
          storeName={storeName}
          fieldKey={"name"}
          method={method}
          title="Kontakt"
        />

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"contact_email"}
            method={method}
            title="Epost"
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"contact_phone"}
            method={method}
            title="Telefon"
          />
        </DoubleFieldWrapper>

        {/* <AddressField
          {...{
            fieldKey: "invoice_address",
            title: "Fakturaadress",
            method,
            storeName,
          }}
        /> */}

        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Säkerhet</OverviewTitle>
          </OverviewTitleWrapper>

          <Toggle
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.enforce_mfa}
            title="Tvinga 2-faktorsautentisering"
            onToggle={() => toggleModule("enforce_mfa")}
          />
        </InnerBox>

        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Moduler</OverviewTitle>
          </OverviewTitleWrapper>

          <Toggle
            disabled={getIsDisabled("allow_billecta")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_billecta}
            title="Pigello Hyresavisering"
            onToggle={() => toggleModule("allow_billecta")}
          />

          <Toggle
            disabled={getIsDisabled("allow_management")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_management}
            title="Pigello Förvaltningsportal"
            onToggle={() => toggleModule("allow_management")}
          />

          <Toggle
            disabled={getIsDisabled("allow_tenantportal")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_tenantportal}
            title="Pigello Mina sidor"
            onToggle={() => toggleModule("allow_tenantportal")}
          />

          <Toggle
            disabled={getIsDisabled("allow_market")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_market}
            title="Pigello Marknad"
            onToggle={() => toggleModule("allow_market")}
          />

          <Toggle
            disabled={getIsDisabled("allow_brf")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_brf}
            title="Pigello BRF"
            onToggle={() => toggleModule("allow_brf")}
          />

          <Toggle
            disabled={getIsDisabled("allow_errand_faults")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_errand_faults}
            title="Pigello TF - Felanmälan"
            onToggle={() => toggleModule("allow_errand_faults")}
          />

          <Toggle
            disabled={getIsDisabled("allow_errand_inspections")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_errand_inspections}
            title="Pigello TF - Besiktning"
            onToggle={() => toggleModule("allow_errand_inspections")}
          />

          <Toggle
            disabled={getIsDisabled("allow_errand_roundings")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_errand_roundings}
            title="Pigello TF - Rondering"
            onToggle={() => toggleModule("allow_errand_roundings")}
          />

          <Toggle
            disabled={getIsDisabled("allow_errand_gov")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_errand_gov}
            title="Pigello TF - Myndighetskrav"
            onToggle={() => toggleModule("allow_errand_gov")}
          />

          <Toggle
            disabled={getIsDisabled("allow_imd")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_imd}
            title="Pigello IMD"
            onToggle={() => toggleModule("allow_imd")}
          />

          <Toggle
            disabled={getIsDisabled("allow_insights")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_insights}
            title="Pigello Insights"
            onToggle={() => toggleModule("allow_insights")}
          />

          <Toggle
            disabled={getIsDisabled("allow_scrive")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_scrive}
            title="Scrive"
            onToggle={() => toggleModule("allow_scrive")}
          />

          <Toggle
            disabled={getIsDisabled("allow_fastdoc")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_fastdoc}
            title="Fastdok"
            onToggle={() => toggleModule("allow_fastdoc")}
          />

          <Toggle
            disabled={getIsDisabled("allow_infometric")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_infometric}
            title="Infometric"
            onToggle={() => toggleModule("allow_infometric")}
          />

          <Toggle
            disabled={getIsDisabled("allow_elvaco")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_elvaco}
            title="Elvaco"
            onToggle={() => toggleModule("allow_elvaco")}
          />

          <Toggle
            disabled={getIsDisabled("allow_fortnox")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_fortnox}
            title="Fortnox (Ej via billecta)"
            onToggle={() => toggleModule("allow_fortnox")}
          />

          <Toggle
            disabled={getIsDisabled("allow_twilio")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_twilio}
            title="Twilio"
            onToggle={() => toggleModule("allow_twilio")}
          />

          <Toggle
            disabled={getIsDisabled("allow_bokamera")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_bokamera}
            title="Bokamera"
            onToggle={() => toggleModule("allow_bokamera")}
          />

          <Toggle
            disabled={getIsDisabled("allow_yourblock")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_yourblock}
            title="YourBlock"
            onToggle={() => toggleModule("allow_yourblock")}
          />

          <Toggle
            disabled={getIsDisabled("allow_erp")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_erp}
            title="ERP"
            onToggle={() => toggleModule("allow_erp")}
          />

          <Toggle
            disabled={getIsDisabled("allow_homeq")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_homeq}
            title="HomeQ"
            onToggle={() => toggleModule("allow_homeq")}
          />
          <Toggle
            disabled={getIsDisabled("allow_sbf")}
            extraStyle={{ marginBottom: 8 }}
            isRedGreen
            value={instance?.allow_sbf}
            title="Bostadsförmedlningen Sthlm"
            onToggle={() => toggleModule("allow_sbf")}
          />
        </InnerBox>
        <div>
          <PrimaryButton
            clicked={() => {
              setStep(STEPS.CONFIRM);
            }}
            title={"Gå vidare"}
          />
        </div>
      </InnerBox>
    </DetailPageBox>
  );

  const confirmStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      {loading && <OverlaySpinner />}
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Gå tillbaka"
        iconType="arrow-back"
        clicked={() => {
          setStep(STEPS.EDIT);
        }}
      />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Bekräfta uppdatering av kund</OverviewTitle>
          <OverviewSubtitle>Kontrollera att datan är korrekt</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <DetailInfo
        infoObj={{
          Kundinformation: [
            {
              title: "Bolagets namn",
              value: instance?.name,
            },
            {
              title: "Orgnr",
              value: instance?.orgnr,
            },
            {
              title: "Kontaktperson",
              value: `${instance?.contact_name}`,
            },
            {
              title: "E-post",
              value: instance?.contact_email,
            },
            {
              title: "Telefon",
              value: instance?.contact_phone,
            },
            // {
            //   title: "Fakturaadress",
            //   value:
            //     instance?.invoice_address?.base ||
            //     instance?.invoice_address?.str_representation,
            // },
          ],
        }}
      />
      <DetailInfo
        infoObj={{
          Säkerhet: [
            {
              title: "Tvinga 2-faktorsautentisering",
              value: (
                <StatusLabel state={instance?.enforce_mfa ? 0 : 6}>
                  {instance?.enforce_mfa ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
          ],
        }}
      />

      <DetailInfo
        infoObj={{
          Moduler: [
            {
              title: "Pigello Hyresavisering",
              value: (
                <StatusLabel state={instance?.allow_billecta ? 0 : 6}>
                  {instance?.allow_billecta ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello Förvaltning",
              value: (
                <StatusLabel state={instance?.allow_management ? 0 : 6}>
                  {instance?.allow_management ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello Mina Sidor",
              value: (
                <StatusLabel state={instance?.allow_tenantportal ? 0 : 6}>
                  {instance?.allow_tenantportal ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello Marknad",
              value: (
                <StatusLabel state={instance?.allow_market ? 0 : 6}>
                  {instance?.allow_market ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello BRF",
              value: (
                <StatusLabel state={instance?.allow_brf ? 0 : 6}>
                  {instance?.allow_brf ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello TF - Felanmälan",
              value: (
                <StatusLabel state={instance?.allow_errand_faults ? 0 : 6}>
                  {instance?.allow_errand_faults ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello TF - Besiktning",
              value: (
                <StatusLabel state={instance?.allow_errand_inspections ? 0 : 6}>
                  {instance?.allow_errand_inspections ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello TF - Rondering",
              value: (
                <StatusLabel state={instance?.allow_errand_roundings ? 0 : 6}>
                  {instance?.allow_errand_roundings ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello TF - Myndighetskrav",
              value: (
                <StatusLabel state={instance?.allow_errand_gov ? 0 : 6}>
                  {instance?.allow_errand_gov ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello IMD",
              value: (
                <StatusLabel state={instance?.allow_imd ? 0 : 6}>
                  {instance?.allow_imd ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Pigello Insights",
              value: (
                <StatusLabel state={instance?.allow_insights ? 0 : 6}>
                  {instance?.allow_insights ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Scrive",
              value: (
                <StatusLabel state={instance?.allow_scrive ? 0 : 6}>
                  {instance?.allow_scrive ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Fastdok",
              value: (
                <StatusLabel state={instance?.allow_fastdoc ? 0 : 6}>
                  {instance?.allow_fastdoc ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Infometric",
              value: (
                <StatusLabel state={instance?.allow_infometric ? 0 : 6}>
                  {instance?.allow_infometric ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },

            {
              title: "Elvaco",
              value: (
                <StatusLabel state={instance?.allow_elvaco ? 0 : 6}>
                  {instance?.allow_elvaco ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Fortnox (Ej via Billecta)",
              value: (
                <StatusLabel state={instance?.allow_fortnox ? 0 : 6}>
                  {instance?.allow_fortnox ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Twilio",
              value: (
                <StatusLabel state={instance?.allow_twilio ? 0 : 6}>
                  {instance?.allow_twilio ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Bokamera",
              value: (
                <StatusLabel state={instance?.allow_bokamera ? 0 : 6}>
                  {instance?.allow_bokamera ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "YourBlock",
              value: (
                <StatusLabel state={instance?.allow_yourblock ? 0 : 6}>
                  {instance?.allow_yourblock ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "ERP",
              value: (
                <StatusLabel state={instance?.allow_erp ? 0 : 6}>
                  {instance?.allow_erp ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "HomeQ",
              value: (
                <StatusLabel state={instance?.allow_homeq ? 0 : 6}>
                  {instance?.allow_homeq ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
            {
              title: "Bostadsförmedlningen Sthlm",
              value: (
                <StatusLabel state={instance?.allow_sbf ? 0 : 6}>
                  {instance?.allow_sbf ? "Aktiv" : "Inaktiv"}
                </StatusLabel>
              ),
            },
          ],
        }}
      />

      <PrimaryButton title="Uppdatera kund" clicked={onSubmit} />
    </DetailPageBox>
  );

  return (
    <DetailInnerWrapper>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Avbryt & gå tillbaka "
        iconType="arrow-back"
        clicked={() => goBack()}
      />
      <StepDisplay steps={steps} activeIndex={step - 1} />

      {step === STEPS.SELECT_CUSTOMER && <>{selectStep()}</>}
      {step === STEPS.EDIT && <>{editStep()}</>}
      {step === STEPS.CONFIRM && <>{confirmStep()}</>}
    </DetailInnerWrapper>
  );
}
